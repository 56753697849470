<template>
    <div>
        <el-dialog v-dialogDrag class="settlement-container" title="发起结算" :append-to-body="true" :visible.sync="drawerSettlement" width="830px" :before-close="handleClose" :close-on-click-modal="false">
            <table v-if="settleType == 'launch'" class="settlement-table">
                <tr class="table-title"><td>派单总金额（元）</td><td class="common-form-BT">加班费（元）</td><td class="common-form-BT">服务扣款（元）</td><td>结算金额（元）</td></tr>
                <tr class="table-result">
                    <td>{{detailInfo.totalPrice ? (detailInfo.totalPrice).toFixed(2) : '0.00'}}</td>
                    <td class="bule-btn">
                        <!-- <el-input class="edit-input" type="number" maxlength="20" v-model="settlementForm.price" placeholder="请输入内容"></el-input> -->
                        <el-input-number class="edit-input" :controls="false" placeholder="请填写" v-model="settlementForm.overtimePrice"></el-input-number>
                    </td>
                    <td class="bule-btn">
                        <el-input-number class="edit-input" :controls="false" placeholder="请填写" v-model="settlementForm.serviceCharge"></el-input-number>
                    </td>
                    <td>{{((detailInfo.totalPrice || 0) + (settlementForm.overtimePrice || 0) - (settlementForm.serviceCharge || 0)).toFixed(2)}}</td>
                </tr>
                <tr class="table-title"><td>结算类型</td><td class="common-form-BT">结算方式</td><td class="common-form-BT">收款人</td><td class="common-form-BT">收款账户</td></tr>
                <tr class="table-result">
                    <td>供应商结算</td>
                    <td class="bule-btn">
                        <el-select class="edit-input" v-model="settlementForm.settleWay" placeholder="请选择结算方式">
                            <el-option v-for="( item, index ) in paySelect" :key="index" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </td>
                    <td class="bule-btn">
                        <el-input class="edit-input" maxlength="50" v-model="settlementForm.collectionName" placeholder="请输入收款人"></el-input>
                    </td>
                    <td class="bule-btn">
                        <el-input class="edit-input" maxlength="50" v-model="settlementForm.collectionAccount" placeholder="请输入收款账户"></el-input>
                    </td>
                </tr>
                <tr class="table-title"><td colspan="2" class="common-form-BT">联系方式</td><td colspan="2" class="common-form-BT">通知人</td></tr>
                <tr class="table-result">
                    <td colspan="2" class="bule-btn">
                        <el-input class="edit-input" maxlength="100" v-model="settlementForm.phone" placeholder="请输入联系方式"></el-input>
                    </td>
                    <td colspan="2" class="notice-td">
                        <div class="notice-container">
                            <div>
                                <span v-for="(item, index) in noticeList" :key="index" class="notice-item">{{item.name}}</span>
                            </div>
                            <div class="notice-right bule-btn" @click="() => { personShow() }">选择人员</div>
                        </div>
                        
                    </td>
                </tr>
                <tr class="table-title"><td colspan="4">调价说明</td></tr>
                <tr class="table-result">
                    <td colspan="4" style="padding: 0">
                        <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="settlementForm.remark" maxlength="500" show-word-limit></el-input>
                    </td>
                </tr>
            </table>
            <table v-if="settleType == 'select'" class="settlement-table">
                <tr class="table-title"><td>派单总金额（元）</td><td>加班费（元）</td><td>服务扣款（元）</td><td>结算金额（元）</td></tr>
                <tr class="table-result">
                    <td>{{detailInfo.totalPrice ? (detailInfo.totalPrice).toFixed(2) : '0.00'}}</td>
                    <td>{{detailInfo.overtimePrice ? (detailInfo.overtimePrice).toFixed(2) : '0.00'}}</td>
                    <td>{{detailInfo.serviceCharge ? (detailInfo.serviceCharge).toFixed(2) : '0.00'}}</td>
                    <td>{{detailInfo.settlePrice ? (detailInfo.settlePrice).toFixed(2) : '0.00'}}</td>
                </tr>
                <tr class="table-title"><td>结算类型</td><td>结算方式</td><td>收款人</td><td>收款账户</td></tr>
                <tr class="table-result">
                    <td>供应商结算</td>
                    <td>{{detailInfo.settleWayName || '--'}}</td>
                    <td>{{detailInfo.collectionName || '--'}}</td>
                    <td>{{detailInfo.collectionAccount || '--'}}</td>
                </tr>
                <tr class="table-title"><td colspan="2">联系方式</td><td colspan="2">通知人</td></tr>
                <tr class="table-result">
                    <td colspan="2">{{detailInfo.phone || '--'}}</td>
                    <td colspan="2">{{detailInfo.noticeName || '--'}}</td>
                </tr>
                <tr class="table-title"><td colspan="4">调价说明</td></tr>
                <tr class="table-result">
                    <td colspan="4">{{detailInfo.remark || '--'}}</td>
                </tr>
            </table>
            <br/>
            <div v-if="settleType == 'launch'" class="common-uploadBnList-container">
                <div class="fileBtn-container">
                    <div class="fileBtn"> 上传附件 <input class="file-input" type="file" @input="(e) => {uploadFile(e)}"></div>
                    上传文件及图片，大小不超过50MB
                </div>
                <br/>
                <div class="fileList-container">
                    <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                        <div class="item-name">
                            <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                        </div>
                        <div @click="() => {deleteFlie(index)}" class="deleteBtn">删除</div>
                    </div>
                </div>
            </div>
            <div v-if="settleType == 'select'" class="common-uploadBnList-container">
                <div class="fileList-container">
                    <div v-for="(item, index) in fileList" :key="index" class="fileList-item">
                        <div class="item-name">
                            <common-picture :fileUrl="item.filedUrl" :fileType="item.filedType" :fileName="item.filedName"></common-picture>
                        </div>
                    </div>
                </div>
            </div>
            <span v-if="settleType == 'launch'" slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="handleClose">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { settleCommit() }">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="选择人员" :append-to-body="true" :visible.sync="personVisible" width="630px" :before-close="() => { personVisible = false }" :close-on-click-modal="false">
            <div class="common-chooseItem-container">
                <div v-for="(item, index) in checkedList" :key="index" class="common-choose-item">
                    <span>{{item.name}}</span><i @click="() => { deletePerson(item) }" class="el-icon-circle-close"></i>
                </div>
            </div>
            <br/><br/>
            <div class="common-person-container">
                <div class="person-title">
                    <div class="left-text">组织成员</div>
                    <div class="right-seach">
                        <el-input class="seach-input" placeholder="请输入内容" prefix-icon="el-icon-search" @change="(val) => seachFun(val)" v-model="seachInfo"></el-input>
                    </div>
                </div>
                <div class="person-content">
                    <div class="content-left">
                        <el-tree :data="departData" :props="defaultProps" @node-click="(val) => { departTreeFun(val) }"></el-tree>
                    </div>
                    <div class="content-right">
                        <div v-for="( item, index ) in personData" :key="index">
                            <el-checkbox v-model="item.checked" @change="(val) => { changePerson(val, item) }">{{item.nickName}}</el-checkbox>
                        </div>
                        
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="() => { personVisible = false }">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { noticeList =  [...checkedList]; personVisible = false}">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { commonDepartAll, personList } from "../../../service/common.js"
import { upload } from "../../../service/upload.js";
import { Config } from "../../../utils/index.js"
import { settleCommit, settleIdDetail } from "../../../service/assignOrder.js"
import CommonPicture from "../../../components/common/commonPicture.vue"
export default {
    components: { CommonPicture },
    props: ["drawerSettlement"],
    data () {
        return {
            loading: false,
            personVisible: false, // 选择人员
            detailInfo: {},
            departData: [], // 部门列表
            personData: [],
            checkedList: [], // 选中的人员
            noticeList: [], // 选中的通知人
            seachInfo: '',
            textarea: '',
            defaultProps: {
                children: 'child',
                label: 'name'
            },
            paySelect: [
                {label: '银行卡', value: 'bankCard'},
                {label: '支付宝', value: 'alipay'},
                {label: '微信', value: 'wechat'},
                {label: '现金', value: 'cash'},
                {label: '其他', value: 'other'},
            ],
            settlementForm: {
                disOrderNo: '',
                overtimePrice: 0,
                serviceCharge: 0,
                settlePrice: 0,
                settleWay: "",
                collectionName: "",
                collectionAccount: "",
                phone: "",
                noticeId: [],
                remark: "",
            },
            fileList: [],
            settleType: 'select',
        }
    },
    methods: {
        async deleteFlie (i) {  //  删除文件
            let fileList = [...this.fileList]
            fileList = fileList.filter((item, index)=>{
                return i != index
            })
            this.fileList = fileList
        },
        async uploadFile (e) { // 上传文件
            let fileList = [...this.fileList]
            let file = e.target.files[0];
            let fileName = file.name;
            let format = file.name.split(".")[file.name.split(".").length - 1];
            if (file.size / 1024 > 1024 * 50) {
                e.target.value = "";
                return this.$message.error('文件大小不能超过50M')
            }
            if (!Config.fileType.PWEIType.includes(format)) {
                e.target.value = "";
                return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
            }
            this.loading = true
            let { data, code } = await upload({ file: file });
            this.loading = false
            if ( code == 200 ) {
                this.$message.success("上传成功")
                fileList.push({ filedName: fileName, filedUrl: data.filename, filedId: data.id, filedType: Config.fileType.imgType.includes(format) ? 'imgText' : 'file'})
                this.fileList = fileList
            }
            e.target.value = ''
        },
        async settleCommit() { // 发起结算提交
            let settlementForm = {...this.settlementForm}
            let noticeList = [...this.noticeList]
            let fileList = [...this.fileList]
            if (!settlementForm.overtimePrice && settlementForm.overtimePrice != 0) { return this.$message.error("请填写加班费") }
            if (!settlementForm.serviceCharge && settlementForm.serviceCharge != 0) { return this.$message.error("请填写服务扣款") }
            if (!String(settlementForm.settleWay)) { return this.$message.error("请选择结算方式") }
            if (!String(settlementForm.collectionName)) { return this.$message.error("请填写收款人") }
            if (!String(settlementForm.collectionAccount)) { return this.$message.error("请填写收款账户") }
            if (!String(settlementForm.phone)) { return this.$message.error("请填写联系方式") }
            if (!noticeList || !noticeList.length) { return this.$message.error("请选择通知人") }
            let newNotice = []
            let newFileList = []
            noticeList.map((item) => {
                newNotice.push(item.id) 
            })
            fileList.map((item) => {
                newFileList.push(item.filedId)
            })
            settlementForm.noticeId = newNotice.join(',')
            settlementForm.fileIds = newFileList.join(',')
            settlementForm.settlePrice = this.detailInfo.totalPrice + settlementForm.overtimePrice - settlementForm.serviceCharge
            let { code } = await settleCommit(settlementForm)
            if (code == 200) {
                this.$message.success("发起成功")
                this.handleClose()
            }
        },
        seachFun (val) { // 搜索人员
            let detailInfo = {...this.detailInfo}
            let params = {
                companyId: detailInfo.companyId,
                name: val,
                pageNum: 1,
                pageSize: 999999
            }
            this.personList(params)
        },
        deletePerson (row) { // 删除选中人员
            let checkedList = [...this.checkedList]
            let personData = [...this.personData]
            checkedList = checkedList.filter((item) => {
                return row.id != item.id
            })
            personData.map((item) => {
                if (row.id == item.id) {
                    item.checked = false
                }
            })
            this.checkedList = checkedList
            this.personData = personData
        },
        changePerson(val, row) { // 选人员变动
            let checkedList = [...this.checkedList]
            if (val) {
                checkedList.push({id: row.id, name: row.nickName})
            } else {
                checkedList = checkedList.filter((item) => {
                    return row.id != item.id
                })
            }
            this.checkedList = checkedList
        },
        departTreeFun (val) { //部门选择
            let detailInfo = {...this.detailInfo}
            let params = {
                companyId: detailInfo.companyId,
                userDepartId: val.id,
                pageNum: 1,
                pageSize: 999999
            }
            this.personList(params)
        },
        async personList(params) { // 获取人员
            let { data } = await personList(params)
            let checkedList = [...this.checkedList]
            if (data.records && data.records.length) {
                data.records.map((item) => {
                    item.checked = false
                    checkedList.map((itemTwo) => {
                        if (item.id == itemTwo.id) {
                            item.checked = true
                        }
                    })
                })
            }
            this.personData = data.records || []
        },
        async commonDepartAll(companyId) { //获取部门
            let { data } = await commonDepartAll({}, companyId)
            this.departData = data || []
        },
        personShow() {
            let noticeList = [...this.noticeList]
            let personData = [...this.personData]
            personData.map((item) => {
                item.checked = false
                noticeList.map((itemTwo) => {
                    if (item.id == itemTwo.id) {
                        item.checked = true
                    }
                })
            })
            this.checkedList = noticeList
            this.personData = personData
            this.personVisible = true
        },
        async getDetailInfo(info, type) { // 父组件传值-// 发起结算传值 type: (launch: 发起结算， select： 查看详情) 
            this.settleType = type
            if (type == 'launch') {
                info.totalPrice = info.totalPrice || 0
                this.detailInfo = info || {}
                this.commonDepartAll(info.companyId)
                this.settlementForm = {
                    disOrderNo: info.disOrderNo,
                    overtimePrice: 0,
                    serviceCharge: 0,
                    settlePrice: 0,
                    settleWay: "",
                    collectionName: "",
                    collectionAccount: "",
                    phone: "",
                    noticeId: [],
                    remark: "",
                }
            } else {
                 let { data } = await settleIdDetail({id: info.id})
                let fileList = []
                if ( data.fileList &&  data.fileList.length) {
                    data.fileList.map((item) => {
                        fileList.push({ filedName: item.fileName, filedUrl: item.filedUrl, filedId: item.filedId, filedType: Config.fileType.imgType.includes((item.fileName.split('.')[item.fileName.split('.').length - 1])) ? 'imgText' : 'file'})
                    })
                }
                this.fileList = fileList
                this.detailInfo = data
            }
            
        },
        handleClose() {
            this.$emit('handleCloseSettlement')
        }
    }

}
</script>
<style lang="less" scoped>
.settlement-container { // 发起结算弹窗
    .common-form-BT {
        &::after {
            left: 6px;
        }
    }
    .settlement-table {
        .bule-btn {
            color: #1890ff;
            cursor: pointer;
            padding: 0;
            vertical-align: top;
            .edit-input {
                width: 100%;
                height: 44px;
                input::-webkit-input-placeholder {
                    color: #1890ff;
                }
                input::-moz-input-placeholder {
                    color: #1890ff;
                }
                input::-ms-input-placeholder {
                    color: #1890ff;
                }
                /deep/.el-input__inner {
                    height: 100% !important;
                    border: 0 !important;
                    color: #1890ff;
                    text-align: left;
                    padding: 0 18px
                }
                /deep/.el-input {
                    height: 100%;
                }
                /deep/.el-input__icon {
                    height: 100%;
                }
            }
        }
        td {
            color: #666;
            font-size: 14px;
            border: 1px solid #dedede;
            padding: 12px 18px;
            /deep/.el-textarea__inner {
                border: 0;
            }
        }
        .table-title {
            background: #f2f2f2;
            td {
                font-weight: 700;
            }
        }
        .notice-td {
            padding-top: 6px;
        }
        .notice-container {
            display: flex;
            justify-content: space-between;
            .notice-right {
                white-space: nowrap;
                padding-top: 6px;
            }
            .notice-item {
                color: #fff;
                background: #1890ff;
                border-radius: 4px;
                padding: 3px 10px;
                font-size: 12px;
                margin: 8px 8px 0 0;
                display: inline-block;
            }
        }
    }
    
}
</style>